import { Injectable } from '@angular/core';
import { DataApiService, DataOptions, HttpOptions, BaseService } from '@xpo-ltl/data-api';
import { ConfigManagerService } from '@xpo-ltl/config-manager';

import {
	CancelHostlerInstructionsByCorrelationIdQuery,
	CancelHostlerInstructionsByCorrelationIdResp,
	CombineHostlerInstructionRqst,
	CombineHostlerInstructionResp,
	CreateHostlerInstructionsRqst,
	CreateHostlerInstructionsResp,
	DeleteHostlerPath,
	DeleteHostlerInstructionPath,
	DeleteParkingLocationPath,
	DeleteTemplateJobPath,
	GetGroupHostlerInstructionByIdResp,
	GetGroupHostlerInstructionByIdPath,
	GetHostlerByIdResp,
	GetHostlerByIdPath,
	GetHostlerInstructionByIdResp,
	GetHostlerInstructionByIdPath,
	GetParkingLocationByIdResp,
	GetParkingLocationByIdPath,
	GetTemplateByIdResp,
	GetTemplateByIdPath,
	GetTemplateJobByIdResp,
	GetTemplateJobByIdPath,
	ListCombinableHostlerInstructionsResp,
	ListCombinableHostlerInstructionsQuery,
	ListHostlerInstructionsResp,
	ListHostlerInstructionsQuery,
	ListHostlersBySicResp,
	ListHostlersBySicQuery,
	ListParkingLocationsBySicResp,
	ListParkingLocationsBySicQuery,
	ListParkingLocationsBySicPath,
	ListTemplateJobsBySicResp,
	ListTemplateJobsBySicQuery,
	ListTemplateJobsBySicPath,
	ListTemplatesBySicResp,
	ListTemplatesBySicQuery,
	ListTemplatesBySicPath,
	UpsertDependentHostlerInstructionsRqst,
	UpsertDependentHostlerInstructionsResp,
	UpsertGroupHostlerInstructionRqst,
	UpsertGroupHostlerInstructionResp,
	UpsertHostlerRqst,
	UpsertHostlerResp,
	UpsertHostlerInstructionRqst,
	UpsertHostlerInstructionResp,
	UpsertParkingLocationRqst,
	UpsertParkingLocationResp,
	UpsertTemplateJobRqst,
	UpsertTemplateJobResp
} from './api-yardmanagement';

import {
	GetHealthCheckResp,
	Envelope,
	User
} from '@xpo-ltl/sdk-common';

import { Observable } from 'rxjs';

@Injectable()
export class YardManagementApiService extends BaseService {
	private static YardManagementApiEndpoint = 'yardmanagementApiEndpoint';

	constructor(private dataApiService: DataApiService, private configManager: ConfigManagerService) {
		super();
	}

	/**
	* Health check URL. Responds with success message if the service is running.
	*/
	public healthCheck(
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<Envelope<GetHealthCheckResp>> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/health-check'),
			{
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* List of resources.
	*/
	public options(
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<void> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/options'),
			{
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* Details of user invoking the API.
	*/
	public loggedInUser(
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<Envelope<User>> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/appusers/logged-in-user'),
			{
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* This operation cancels all hostler instructions for the correlationId that are in Pending status.  
	* <br/>
	* <br/>Pre-condition:
	* <br/>1. A valid correlationId is provided.
	* <br/>
	* <br/>Post-conditions:
	* <br/>1. If successful, then all hostler instructions with the correlationId provided that are in Pending status are set to Canceled status.  The count of canceled hostler instructions is returned.
	* <br/>2. Otherwise an appropriate error message is returned.
	*/
	public cancelHostlerInstructionsByCorrelationId(request: CancelHostlerInstructionsByCorrelationIdQuery,
							   queryParams: CancelHostlerInstructionsByCorrelationIdQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<Envelope<CancelHostlerInstructionsByCorrelationIdResp>> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/canceled-hostler-instructions'),
			{
				queryParams: queryParams
				,body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.post(apiRequest);
	}

	/**
	* Combine the information for a hostler instruction.
	* <br/>
	* <br/>Pre-conditions:
	* <br/>1. Input fields must be valid.
	* <br/>2. It is mandatory to receive the hostler request and the hostler combinable request
	* <br/>
	* <br/>Post-condition:
	* <br/>1. If the combine is successful, then YMS_HOSTLER_INSTRUCTION will be updated.
	* <br/>2. Otherwise an appropriate error message will be returned.                                     
	*/
	public CombineHostlerInstruction(request: CombineHostlerInstructionRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<Envelope<CombineHostlerInstructionResp>> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/combinable-hostler-instructions'),
			{
				body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.put(apiRequest);
	}

	/**
	* This operation creates one or more hostler instuctions using the input provided.  
	* <br/>
	* <br/>Pre-conditions:
	* <br/>1. The input fields provided must be valid.
	* <br/>
	* <br/>Post-condition:
	* <br/>1. If the input is valid, then the job ID, a record creation request count, and the hostler instructions that were created along with the ones that failed will be returned.
	* <br/>2. Otherwise an appropriate error message will be returned.
	*/
	public createHostlerInstructions(request: CreateHostlerInstructionsRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<Envelope<CreateHostlerInstructionsResp>> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/hostler-instructions'),
			{
				body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.post(apiRequest);
	}

	/**
	* This operation will delete the hostler associated with the employee ID provided.
	* <br/>
	* <br/>Pre-conditions:
	* <br/>1. A valid employeeId is provided as input.
	* <br/>
	* <br/>Post-conditions:
	* <br/>1. If the employeeId is found, the status for that Hostler is deleted in the table YMS_HOSTLER. 
	* <br/>2. If the employeeId is invalid, an appropriate error message is returned.
	*/
	public deleteHostler(
							   pathParams: DeleteHostlerPath,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<void> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/hostlers/{employeeId}'),
			{
		    	pathParams: pathParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.delete(apiRequest);
	}

	/**
	* This operation will delete the hostler instruction record that is associated with the ID provided.
	* <br/>
	* <br/>Pre-conditions:
	* <br/>1. The instruction ID is provided as input.
	* <br/>
	* <br/>Post-conditions:
	* <br/>1. If the instruction ID is found, the status for that Hostler instruction status cd is updated to âXâ (Cancelled) in the DB.  Any other data associated with that instruction will remain unaltered in the tables in the database.
	* <br/>2. If the instruction ID is invalid, an appropriate error message is returned.
	*/
	public deleteHostlerInstruction(
							   pathParams: DeleteHostlerInstructionPath,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<void> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/hostler-instructions/{instructionId}'),
			{
		    	pathParams: pathParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.delete(apiRequest);
	}

	/**
	* This operation is created specifically for the YMS Application.
	* <br/>
	* <br/>This operation will delete the parking location that is associated with the ID provided.
	* <br/>
	* <br/>Pre-conditions:
	* <br/>1. The parking location ID is provided as input.
	* <br/>
	* <br/>Post-conditions:
	* <br/>1. If the parking location ID is found, the parking location record is deleted from the YMS_PARKING_LOCATION DB. 
	* <br/>2. If the parking location ID is invalid, an appropriate error message is returned.
	*/
	public deleteParkingLocation(
							   pathParams: DeleteParkingLocationPath,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<void> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/parking-locations/{parkingLocationId}'),
			{
		    	pathParams: pathParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.delete(apiRequest);
	}

	/**
	* This operation is created specifically for the YMS Application.
	* <br/>
	* <br/>This operation will delete the template job that is associated with the ID provided.
	* <br/>
	* <br/>Pre-conditions:
	* <br/>1. A valid template job ID must be provided.
	* <br/>
	* <br/>Post-conditions:
	* <br/>1. If the template job ID is found, then the template job record is deleted.
	* <br/>2. If the template job ID is invalid, an appropriate error message is returned.
	*/
	public deleteTemplateJob(
							   pathParams: DeleteTemplateJobPath,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<void> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/templete-jobs/{templateJobId}'),
			{
		    	pathParams: pathParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.delete(apiRequest);
	}

	/**
	* This operation retrieves the group and their children requests.
	* <br/>
	* <br/>Mandatory Criteria:
	* <br/>- Parent ID
	* <br/>
	* <br/>Pre-conditions:
	* <br/>1. Parent ID is provided as the input.
	* <br/>
	* <br/>Post-conditions:
	* <br/>1. If the group ID is valid, details are returned for that group instruction and the related requests. 
	* <br/>2. If the Instruction ID is invalid or is not a group, an appropriate error message is returned in the response.
	*/
	public getGroupHostlerInstructionById(
							   pathParams: GetGroupHostlerInstructionByIdPath,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<Envelope<GetGroupHostlerInstructionByIdResp>> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/hostler-instructions/{instructionId}/group-request'),
			{
		    	pathParams: pathParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* This operation retrieves a specific hostler by employee ID.
	* <br/>
	* <br/>Pre-conditions:
	* <br/>1. A valid employeeId is provided as the input.
	* <br/>
	* <br/>Post-conditions:
	* <br/>1. If the employeeId is valid, details are returned for that hostler. 
	* <br/>2. If the employeeId is invalid, an appropriate error message is returned in the response.
	*/
	public getHostlerById(
							   pathParams: GetHostlerByIdPath,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<Envelope<GetHostlerByIdResp>> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/hostlers/{employeeId}'),
			{
		    	pathParams: pathParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* This operation retrieves a specific hostler yard instruction.
	* <br/>
	* <br/>Mandatory Criteria:
	* <br/>- Instruction ID
	* <br/>
	* <br/>Pre-conditions:
	* <br/>1. Instruction ID is provided as the input.
	* <br/>
	* <br/>Post-conditions:
	* <br/>1. If the Instruction ID is valid, details are returned for that hostler instruction. 
	* <br/>2. If the Instruction ID is invalid, an appropriate error message is returned in the response.
	*/
	public getHostlerInstructionById(
							   pathParams: GetHostlerInstructionByIdPath,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<Envelope<GetHostlerInstructionByIdResp>> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/hostler-instructions/{instructionId}'),
			{
		    	pathParams: pathParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* This operation retrieves a specific YMS Parking Location based on the ID provided. 		
	* <br/>
	* <br/>Mandatory Criteria:
	* <br/>- Parking Location ID
	* <br/>
	* <br/>Pre-conditions:
	* <br/>1. Parking Location ID is provided as the input.
	* <br/>
	* <br/>Post-conditions:
	* <br/>1. If the Parking Location ID is valid, return details for that location. 
	* <br/>2. If the Parking LocationID is invalid, return an appropriate error message as the response.
	*/
	public getParkingLocationById(
							   pathParams: GetParkingLocationByIdPath,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<Envelope<GetParkingLocationByIdResp>> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/parking-locations/{parkingLocationId}'),
			{
		    	pathParams: pathParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* This operation retrieves a specific YMS template based on the composite template ID provided.
	* <br/>
	* <br/>Pre-condition:
	* <br/>1. A valid template ID must be provided.
	* <br/>
	* <br/>Post-conditions:
	* <br/>1. If the Template ID is valid and found, then details for that template are returned. 
	* <br/>2. If the Template ID is invalid, an appropriate error message is returned in the response.
	*/
	public getTemplateById(
							   pathParams: GetTemplateByIdPath,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<Envelope<GetTemplateByIdResp>> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/template-groups/{groupingId}/sequences/{templateSequenceNbr}'),
			{
		    	pathParams: pathParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* This operation retrieves a specific YMS Template Job based on the ID provided. 
	* <br/>		
	* <br/>Pre-conditions:
	* <br/>1. A valid Template Job ID must be provided.
	* <br/>
	* <br/>Post-conditions:
	* <br/>1. If the Template Job ID is valid and found, then details for that template job are returned. 
	* <br/>2. If the Template Job ID is invalid, an appropriate error message is returned in the response.
	*/
	public getTemplateJobById(
							   pathParams: GetTemplateJobByIdPath,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<Envelope<GetTemplateJobByIdResp>> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/template-jobs/{templateJobId}'),
			{
		    	pathParams: pathParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* This operation retrieves hostler yard instructions by SIC that could be combined with the request received.
	* <br/>
	* <br/>Pre-conditions:
	* <br/>1. Request to compare:
	* <br/>- HostlerInstruction 
	* <br/>2. ListInfo
	* <br/>
	* <br/>Post-conditions:
	* <br/>1. If hostler instructions are found that match the input criteria, then they will be returned.
	* <br/>2. Otherwise a not found error message will be returned.
	*/
	public listCombinableHostlerInstructions(
							   queryParams: ListCombinableHostlerInstructionsQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<Envelope<ListCombinableHostlerInstructionsResp>> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/combinable-hostler-instructions'),
			{
				queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* This operation retrieves hostler yard instructions by SIC or hostler ID and possibly instruction status code.
	* <br/>
	* <br/>Pre-conditions:
	* <br/>1. Request criteria for any of the following fields is provided (either hostlerID or SIC need to be populated for this to be useful):
	* <br/>- SIC or hostlerId	
	* <br/>- ListInfo
	* <br/>
	* <br/>Optional Criteria:
	* <br/>- SIC
	* <br/>- hostlerId
	* <br/>- Instruction Status
	* <br/>
	* <br/>Post-conditions:
	* <br/>1. If hostler instructions are found that match the input criteria, then they will be returned.
	* <br/>2. Otherwise a not found error message will be returned.
	*/
	public listHostlerInstructions(
							   queryParams: ListHostlerInstructionsQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<Envelope<ListHostlerInstructionsResp>> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/hostler-instructions'),
			{
				queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* This operation retrieves hostlers by the SIC provided.
	* <br/>
	* <br/>Pre-conditions:
	* <br/>1. Request criteria for any of the following fields is provided:
	* <br/>- SIC 
	* <br/>- listInfo
	* <br/>
	* <br/>Post-conditions:
	* <br/>1. If hostlers are found that match the input criteria, then they will be returned.
	* <br/>2. Otherwise a not found error message will be returned.
	*/
	public listHostlersBySic(
							   queryParams: ListHostlersBySicQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<Envelope<ListHostlersBySicResp>> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/hostlers'),
			{
				queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* This operation lists yard parking locations by SIC.
	* <br/>
	* <br/>Pre-conditions:
	* <br/>1. The SIC is required.
	* <br/>
	* <br/>If no Parking Locations match the input criteria, then a not found error message will be returned.
	*/
	public listParkingLocationsBySic(
							   pathParams: ListParkingLocationsBySicPath,
							   queryParams: ListParkingLocationsBySicQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<Envelope<ListParkingLocationsBySicResp>> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/service-centers/{sic}/parking-locations'),
			{
		    	pathParams: pathParams
				,queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* This operation lists yard template jobs by SIC.
	* <br/>
	* <br/>Pre-conditions:
	* <br/>1. A valid SIC is required.
	* <br/>
	* <br/>Post-conditions:
	* <br/>1. If successful, then a list of the matching Template Jobs will be returned.
	* <br/>2. If no Template Jobs match the input criteria, then a 404 - Not Found error message will be returned.
	*/
	public listTemplateJobsBySic(
							   pathParams: ListTemplateJobsBySicPath,
							   queryParams: ListTemplateJobsBySicQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<Envelope<ListTemplateJobsBySicResp>> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/service-centers/{sicCode}/template-jobs'),
			{
		    	pathParams: pathParams
				,queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* This operation lists yard template for a SIC.
	* <br/>
	* <br/>Pre-conditions:
	* <br/>1. A valid SIC is required.
	* <br/>
	* <br/>Post-conditions:
	* <br/>1. If successful, then a list of the matching Templates will be returned.
	* <br/>2. If no Template match the input criteria, then a 404 - Not Found error message will be returned.
	*/
	public listTemplatesBySic(
							   pathParams: ListTemplatesBySicPath,
							   queryParams: ListTemplatesBySicQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<Envelope<ListTemplatesBySicResp>> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/service-centers/{sicCode}/templates'),
			{
		    	pathParams: pathParams
				,queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* This operation upserts the information for two or more hostler instructions with each being dependent on its predecessor.
	* <br/>
	* <br/>Pre-condition:
	* <br/>1. Input fields must be valid and must contain at least two hostler instructions.
	* <br/>
	* <br/>Post-conditions:
	* <br/>1. If successful, then YMS_HOSTLER_INSTRUCTION records will be upserted sequentially.
	* <br/>2. Starting with the second record, each YMS_HOSTLER_INSTRUCTION record will have the instance ID of the predecssor as its parent.
	* <br/>3. Processing will stop if an upsert fails but previously upserted records will not be rolled back.
	*/
	public upsertDependentHostlerInstructions(request: UpsertDependentHostlerInstructionsRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<Envelope<UpsertDependentHostlerInstructionsResp>> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/dependent-hostler-instructions'),
			{
				body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.put(apiRequest);
	}

	/**
	* Upserts the information for a request part of the group instruction.
	* <br/>
	* <br/>Pre-conditions:
	* <br/>1. Input fields must be valid.
	* <br/>
	* <br/>Post-condition:
	* <br/>1. If the upsert is successful, then YMS_HOSTLER_INSTRUCTION will be updated.
	* <br/>2. Otherwise an appropriate error message will be returned.                                     
	*/
	public upsertGroupHostlerInstructions(request: UpsertGroupHostlerInstructionRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<Envelope<UpsertGroupHostlerInstructionResp>> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/group-request'),
			{
				body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.put(apiRequest);
	}

	/**
	* Upserts the information for a hostler.
	* <br/>
	* <br/>Pre-conditions:
	* <br/>1. Input fields must be valid.
	* <br/>
	* <br/>Post-condition:
	* <br/>1. If the upsert is successful, then YMS_HOSTLER will be updated.
	* <br/>2. Otherwise an appropriate error message will be returned.                                     
	*/
	public upsertHostler(request: UpsertHostlerRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<Envelope<UpsertHostlerResp>> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/hostler'),
			{
				body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.put(apiRequest);
	}

	/**
	* Upserts the information for a hostler instruction.
	* <br/>
	* <br/>Pre-conditions:
	* <br/>1. Input fields must be valid.
	* <br/>
	* <br/>Post-condition:
	* <br/>1. If the upsert is successful, then YMS_HOSTLER_INSTRUCTION will be updated.
	* <br/>2. Otherwise an appropriate error message will be returned.                                     
	*/
	public upsertHostlerInstructions(request: UpsertHostlerInstructionRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<Envelope<UpsertHostlerInstructionResp>> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/hostler-instructions'),
			{
				body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.put(apiRequest);
	}

	/**
	* Upserts a record for a YMS parking location.
	* <br/>
	* <br/>Pre-conditions:
	* <br/>1. Input fields must be valid.
	* <br/>
	* <br/>Post-condition:
	* <br/>1. If the input is valid, the YMS_PARKING_LOCATION table will be updated. 
	* <br/>2. Otherwise an appropriate error message will be returned.                                     
	*/
	public upsertParkingLocation(request: UpsertParkingLocationRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<Envelope<UpsertParkingLocationResp>> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/parking-locations'),
			{
				body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.put(apiRequest);
	}

	/**
	* This operation upserts a record for a YMS template job.
	* <br/>
	* <br/>Pre-conditions:
	* <br/>1. The input fields provided must be valid.
	* <br/>
	* <br/>Post-condition:
	* <br/>1. If the input is valid, then the template job will be upserted. 
	* <br/>2. Otherwise an appropriate error message will be returned.                                     
	*/
	public upsertTemplateJob(request: UpsertTemplateJobRqst,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<Envelope<UpsertTemplateJobResp>> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/template-jobs'),
			{
				body: request
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.put(apiRequest);
	}


	protected getEndPoint(): string {
		return this.configManager.getSetting(YardManagementApiService.YardManagementApiEndpoint);
	}
}
