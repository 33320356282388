import { Injectable } from '@angular/core';
import { DataApiService, DataOptions, HttpOptions, BaseService } from '@xpo-ltl/data-api';
import { ConfigManagerService } from '@xpo-ltl/config-manager';

import {
	GetEquipmentDtlsByPfxSfxResp,
	GetEquipmentDtlsByPfxSfxQuery,
	GetEquipmentDtlsByPfxSfxPath
} from './api-equipment';

import {
	GetHealthCheckResp,
	Envelope,
	User
} from '@xpo-ltl/sdk-common';

import { Observable } from 'rxjs';

@Injectable()
export class EquipmentApiService extends BaseService {
	private static EquipmentApiEndpoint = 'equipmentV2ApiEndpoint';

	constructor(private dataApiService: DataApiService, private configManager: ConfigManagerService) {
		super();
	}

	/**
	* Health check URL. Responds with success message if the service is running.
	*/
	public healthCheck(
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<Envelope<GetHealthCheckResp>> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/health-check'),
			{
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* List of resources.
	*/
	public options(
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<void> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/options'),
			{
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* Details of user invoking the API.
	*/
	public loggedInUser(
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<Envelope<User>> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/appusers/logged-in-user'),
			{
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}

	/**
	* This operation retrieves equipment details based on the equipment prefix text and suffix number provided.  The combination of the two fields is used to identify the equipment.
	* <br/>
	* <br/>Pre-conditions:
	* <br/>1. A valid equipment prefix and suffix are provided.
	* <br/>2. The SIC of the equipment is provided. This field is not required if equipmentDetailCd is blank but if equipmentDetailCd is set to OPSDTL, then SIC is required.
	* <br/>3. equipmentDetailCd is an optional parameter. If this parameter is not supplied, then return only basic equipment information. If this parameter is set to OPSDTL, then return additional operational details about the equipment.
	* <br/>
	* <br/>The additional details which will be returned when equipmentDetailCd is set to OPSDTL are trailerStatus, equipmentDoor, hazmantOnBoardInd, equipmentLength, totalWeight, sealNbr, loadDestSic, loadRelNbr, and loadedShipmentCount.
	* <br/>
	* <br/>Post-conditions:
	* <br/>1. If the equipment exists for the input prefix and suffix, detailed information for the equipment is returned.
	* <br/>2. If the equipment does not exist for the input prefix and suffix, no response is returned.
	* <br/>3. Otherwise an appropriate error message is returned.
	*/
	public getEquipmentDtlsByPfxSfx(
							   pathParams: GetEquipmentDtlsByPfxSfxPath,
							   queryParams: GetEquipmentDtlsByPfxSfxQuery,
                               dataOptions?: DataOptions,
                               httpOptions?: HttpOptions): Observable<Envelope<GetEquipmentDtlsByPfxSfxResp>> {
		const apiRequest = this.generateApiRequest(
			this.getUrl('/equipments/{equipmentPrefix}/{equipmentSuffix}'),
			{
		    	pathParams: pathParams
				,queryParams: queryParams
			},
			dataOptions,
			httpOptions);
		return this.dataApiService.get(apiRequest);
	}


	protected getEndPoint(): string {
		return this.configManager.getSetting(EquipmentApiService.EquipmentApiEndpoint);
	}
}
