import { NgModule } from '@angular/core';
import { CONFIG_MANAGER_SERVICE_CONFIGURATION, ConfigManagerModule } from '@xpo-ltl/config-manager';
import {EquipmentApiService} from './equipment-api.service';

@NgModule({
  imports: [
    ConfigManagerModule
  ],
  providers: [
    EquipmentApiService,
    { provide: CONFIG_MANAGER_SERVICE_CONFIGURATION, useValue: { configFileUrl: './assets/config.json'}},
  ]
})
export class SdkEquipmentModule { }
