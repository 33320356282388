/**
 * Equipment API
 * Version: 2.0
 * Build: 2.0.0.1
 */


/**
 * Path parameters which are used to retrieve equipment details.
 */
export class GetEquipmentDtlsByPfxSfxPath {
	/**
	 * The prefix for the equipment number.
	 * Required: true
	 * Array: false
	 */
	equipmentPrefix:string = undefined;
	/**
	 * The sequencing or serial part of an equipment unit's identifying number.
	 * Required: true
	 * Array: false
	 */
	equipmentSuffix:number = undefined;
}
/**
 * Query parameters which are used to retrieve equipment details.
 */
export class GetEquipmentDtlsByPfxSfxQuery {
	/**
	 * The service center identification code for the current location of the user.
	 * Required: false
	 * Array: false
	 */
	sicCd:string = undefined;
	/**
	 * This is an optional parameter for this service. If this field is blank, return basic details about the equipment. If its value = OPSDTL, return additional details about the equipment.
	 * Required: false
	 * Array: false
	 */
	equipmentDetailCd:string = undefined;
}
/**
 * Response to a request to retrieve equipment details by prefix and suffix.
 */
export class GetEquipmentDtlsByPfxSfxResp {
	/**
	 * The identification information for the equipment.
	 * Required: false
	 * Array: false
	 */
	equipmentInfo:EquipmentInfo = undefined;
}
/**
 * *** Needs to be renamed since Info is part of Legacy naming standards ***
 *    Identification info for equipment.
 */
export class EquipmentInfo {
	/**
	 * The unique identifier for the equipment.
	 * Required: false
	 * Array: false
	 */
	equipmentInstId:string = undefined;
	/**
	 * The one character equipment type code. Valid values are:
	 * D - Dolly
	 * P - Power Tractor 
	 * Z - Straight Truck
	 * T - Trailer 
	 * O - Other
	 * Required: false
	 * Array: false
	 */
	equipmentTypeCd:string = undefined;
	/**
	 * The prefix for the equipment number.
	 * Required: false
	 * Array: false
	 */
	equipmentPrefix:string = undefined;
	/**
	 * The sequencing or serial part of an equipment unit's identifying number.
	 * Required: false
	 * Array: false
	 */
	equipmentSuffix:string = undefined;
	/**
	 * The full eleven character equipment ID in text format.  Usually it is formatted as PPPP-SSSSSS where the P's represent the equipment prefix and the S's represent the equipment suffix.
	 * Required: false
	 * Array: false
	 */
	equipmentIdFullText:string = undefined;
	/**
	 * The current status of the equipment can be one of the following: available, out of service, or city.
	 * Required: false
	 * Array: false
	 */
	currentStatusCd:string = undefined;
	/**
	 * The current location of a piece of equipment.
	 * Required: false
	 * Array: false
	 */
	currentSicCd:string = undefined;
	/**
	 * The additional details for the equipment type.  The equipment type is carried in the TMT database.  This  
	 * is an 'intelligent' code that will help identify the characteristics of a piece of equipment.  E.g. TTC28 - could mean linehaul trailer 28'  long.
	 * Required: false
	 * Array: false
	 */
	equipmentTypeDtlCd:string = undefined;
	/**
	 * The door location at which the equipment is currently located.
	 * Required: false
	 * Array: false
	 */
	equipmentDoor:string = undefined;
	/**
	 * The status of the equipment, specifically the trailer status e.g. LDDK, CLDK, TRAP, etc.
	 * Required: false
	 * Array: false
	 */
	trailerStatus:string = undefined;
	/**
	 * Iindicates if there is a hazmat shipment on the equipment.
	 * Required: false
	 * Array: false
	 */
	hazmatOnBoardInd:boolean = undefined;
	/**
	 * The length of the equipment.
	 * Required: false
	 * Array: false
	 */
	equipmentLength:number = undefined;
	/**
	 * The total weight of the equipment along with any additional equipment loaded on it.
	 * Required: false
	 * Array: false
	 */
	totalWeight:number = undefined;
	/**
	 * The number of the seal that is placed on the trailer after the doors are locked. This number should be the same as the seal on the trailer when it arrives at the destination and is opened.
	 * Required: false
	 * Array: false
	 */
	sealNbr:string = undefined;
	/**
	 * The destination of the load. The load will be broken at this location.
	 * Required: false
	 * Array: false
	 */
	loadDestSic:string = undefined;
	/**
	 * The number that is assigned to the load when the trailer has been loaded and closed.
	 * Required: false
	 * Array: false
	 */
	loadRelNbr:string = undefined;
	/**
	 * The count of the loaded shipments on the equipment.
	 * Required: false
	 * Array: false
	 */
	loadedShipmentCount:number = undefined;
}



