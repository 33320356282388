/**
 * YardManagement API
 * Version: 1.0
 * Build: 1.0.0.26
 */

import {
	ListInfo,
	DataValidationError,
	AuditInfo,
	ActionCd,
	EquipmentQualifierCd,
	EquipmentTypeCd,
	HostlerInstructionCd,
	InstructionStatCd,
	ParkingLocationTypeCd
} from '@xpo-ltl/sdk-common';

/**
 * Request to get the Group hostler instruction record that is associated with the ID provided.
 */
export class GetGroupHostlerInstructionByIdPath {
	/**
	 * The system generated unique identifier for the Group hostler instruction.
	 * Required: true
	 * Array: false
	 */
	instructionId:number = undefined;
}
/**
 * Response to the request to get the Group hostler request record that is associated with the ID provided and the related moves.
 */
export class GetGroupHostlerInstructionByIdResp {
	/**
	 * The group request and its children requests.
	 * Required: false
	 * Array: false
	 */
	groupRequest:GroupRequest = undefined;
}
/**
 * Request to upsert part of a group request
 */
export class UpsertGroupHostlerInstructionRqst {
	/**
	 * An instruction on how to work the yard.
	 * Required: true
	 * Array: false
	 */
	hostlerInstruction:HostlerInstruction = undefined;
}
/**
 * Response to the request to upsert a request part of a group hostler instructions.
 */
export class UpsertGroupHostlerInstructionResp {
	/**
	 * A group instruction updated according to the swap process.
	 * Required: false
	 * Array: false
	 */
	groupRequest:GroupRequest = undefined;
	/**
	 * An instruction on how to work the yard.
	 * Required: false
	 * Array: false
	 */
	hostlerInstruction:HostlerInstruction = undefined;
}
/**
 * Path parameter used to delete the hostler using the employee ID.
 */
export class DeleteHostlerPath {
	/**
	 * The system generated unique identifier for the employee.
	 * Required: true
	 * Array: false
	 */
	employeeId:string = undefined;
}
/**
 * Request to get the hostler associated with the employeeID provided.
 */
export class GetHostlerByIdPath {
	/**
	 * The system generated unique identifier for the employee.
	 * Required: true
	 * Array: false
	 */
	employeeId:string = undefined;
}
/**
 * Response to the request to get the hostler record that is associated with the employeeID provided.
 */
export class GetHostlerByIdResp {
	/**
	 * Details about a hostler, including their employee ID, SIC, and audit trail information.
	 * Required: false
	 * Array: false
	 */
	hostler:Hostler = undefined;
}
/**
 * Query parameters used to list Hostlers by SIC.
 */
export class ListHostlersBySicQuery {
	/**
	 * The service center for which the hostlers are to be retrieved.
	 * Required: false
	 * Array: false
	 */
	sic:string = undefined;
	/**
	 * Pagination information to retrieve the list.
	 * Required: false
	 * Array: false
	 */
	listInfo:ListInfo = undefined;
}
/**
 * Response to the request to list parking locations by SIC.
 */
export class ListHostlersBySicResp {
	/**
	 * A Hostler that is working in a specific SIC.
	 * Required: false
	 * Array: true
	 */
	hostlers:Hostler[] = undefined;
	/**
	 * Pagination information to retrieve the list.
	 * Required: false
	 * Array: false
	 */
	listInfo:ListInfo = undefined;
}
/**
 * Request to upsert a Hostler
 */
export class UpsertHostlerRqst {
	/**
	 * A Hostler that is working in a specific SIC.
	 * Required: true
	 * Array: false
	 */
	hostler:Hostler = undefined;
}
/**
 * Response to the request to upsert a Hostler.
 */
export class UpsertHostlerResp {
	/**
	 * A Hostler that is working in a specific SIC.
	 * Required: true
	 * Array: false
	 */
	hostler:Hostler = undefined;
}
/**
 * Request to cancel hostler instructions based on the correlation ID provided.
 */
export class CancelHostlerInstructionsByCorrelationIdQuery {
	/**
	 * The correlationId of instructions that were created by a given transaction that will be canceled.
	 * Required: true
	 * Array: false
	 */
	correlationIdToCancel:string = undefined;
}
/**
 * Response to the request to cancel hostler instructions based on the correlation ID provided.
 */
export class CancelHostlerInstructionsByCorrelationIdResp {
	/**
	 * The number of records that were canceled.
	 * Required: false
	 * Array: false
	 */
	instructionsCanceledCount:number = undefined;
}
/**
 * Request to combine a hostler instruction.
 */
export class CombineHostlerInstructionRqst {
	/**
	 * The working instruction that is going to be combined
	 * Required: true
	 * Array: false
	 */
	hostlerInstruction:HostlerInstruction = undefined;
	/**
	 * A combinable instruction that is going to be combined
	 * Required: true
	 * Array: false
	 */
	hostlerInstructionCombinable:HostlerInstruction = undefined;
}
/**
 * Response to the request to combine a hostler instruction.
 */
export class CombineHostlerInstructionResp {
	/**
	 * An instruction that was combined.
	 * Required: false
	 * Array: false
	 */
	hostlerInstruction:HostlerInstruction = undefined;
}
/**
 * Request to create multiple hostler instructions.
 */
export class CreateHostlerInstructionsRqst {
	/**
	 * The unique identifier for the service center.
	 * Required: true
	 * Array: false
	 */
	sicCode:string = undefined;
	/**
	 * The identifier of the shift. Types: I - Inbound, D - Day FAC, O - Outbound, N - Night FAC, X - N/A
	 * Required: true
	 * Array: false
	 */
	shiftCd:string = undefined;
	/**
	 * The name of the template used to create the job request.
	 * Required: true
	 * Array: false
	 */
	templateName:string = undefined;
	/**
	 * The identifier of the template grouping.
	 * Required: true
	 * Array: false
	 */
	groupingId:number = undefined;
	/**
	 * The template sequence number for its group.
	 * Required: true
	 * Array: false
	 */
	templateSequenceNbr:number = undefined;
	/**
	 * The template job JSON instance that describes the hostler instrctions that were requested to be created.
	 * Required: true
	 * Array: false
	 */
	templateJobJson:string = undefined;
}
/**
 * Reponse after processing template jobs.  .
 */
export class CreateHostlerInstructionsResp {
	/**
	 * The unique identifier for the template job record.
	 * Required: false
	 * Array: false
	 */
	templateJobId:number = undefined;
	/**
	 * The number of records that the job requested to be created.
	 * Required: false
	 * Array: false
	 */
	instructionCreatedCount:number = undefined;
	/**
	 * The identifier for the transaction.
	 * Required: false
	 * Array: false
	 */
	correlationId:string = undefined;
	/**
	 * A list of instructions that failed to be created.
	 * Required: false
	 * Array: true
	 */
	failedInstructions:InstructionResult[] = undefined;
}
/**
 * Path parameter used to delete the hostler instruction record that is associated with the ID provided.
 */
export class DeleteHostlerInstructionPath {
	/**
	 * The system generated unique identifier for the hostler instruction.
	 * Required: true
	 * Array: false
	 */
	instructionId:number = undefined;
}
/**
 * Path parameter used to get the hostler instruction record that is associated with the ID provided.
 */
export class GetHostlerInstructionByIdPath {
	/**
	 * The system generated unique identifier for the hostler instruction.
	 * Required: true
	 * Array: false
	 */
	instructionId:number = undefined;
}
/**
 * Response to the request to get the hostler instruction record that is associated with the ID provided.
 */
export class GetHostlerInstructionByIdResp {
	/**
	 * An instruction on how to work the yard.
	 * Required: false
	 * Array: false
	 */
	hostlerInstruction:HostlerInstruction = undefined;
}
/**
 * Request to list combinable hostler instructions by SIC or hostler ID.
 */
export class ListCombinableHostlerInstructionsRqst {
	/**
	 * An instruction that is going to be created or updated.
	 * Required: true
	 * Array: false
	 */
	hostlerInstruction:HostlerInstruction = undefined;
}
/**
 * Query parameter used to list combinable hostler instructions by SIC or hostler ID.
 */
export class ListCombinableHostlerInstructionsQuery {
	/**
	 * Pagination information to retrieve the list.
	 * Required: false
	 * Array: false
	 */
	listInfo:ListInfo = undefined;
}
/**
 * Response to the request to list combinable hostler instructions by SIC or hostler ID.
 */
export class ListCombinableHostlerInstructionsResp {
	/**
	 * Instructions that could be combinable.
	 * Required: false
	 * Array: true
	 */
	hostlerInstructions:HostlerInstruction[] = undefined;
	/**
	 * Pagination information to retrieve the list.
	 * Required: false
	 * Array: false
	 */
	listInfo:ListInfo = undefined;
}
/**
 * Query parameters used to list hostler instructions by SIC or hostler ID.
 */
export class ListHostlerInstructionsQuery {
	/**
	 * The location for the hostler yard instructions.
	 * Required: false
	 * Array: false
	 */
	sic:string = undefined;
	/**
	 * The employee ID of the person who will be performing the hostler activity.
	 * Required: false
	 * Array: false
	 */
	assignedTo:string = undefined;
	/**
	 * Indicates that only unassigned instructions should be listed if true and assigned instructions if false.
	 * Required: false
	 * Array: false
	 */
	unassignedInstructionsInd:boolean = undefined;
	/**
	 * The status code of the instruction.
	 * Required: false
	 * Array: true
	 */
	instructionStatCd:InstructionStatCd[] = undefined;
	/**
	 * The employee ID of the supervisor that created the instruction.
	 * Required: false
	 * Array: false
	 */
	createdBy:string = undefined;
	/**
	 * The priority for the instruction.
	 * Required: false
	 * Array: false
	 */
	priorityNbr:string = undefined;
	/**
	 * The prefix of the equipment number to be moved.
	 * Required: false
	 * Array: false
	 */
	equipmentPrefix:string = undefined;
	/**
	 * The suffix of the equipment number to be moved.
	 * Required: false
	 * Array: false
	 */
	equipmentSuffix:string = undefined;
	/**
	 * The door from which the equipment is moving.
	 * Required: false
	 * Array: false
	 */
	fromLocationDoor:string = undefined;
	/**
	 * The door to which the equipment is moving.
	 * Required: false
	 * Array: false
	 */
	toLocationDoor:string = undefined;
	/**
	 * The minimum from or to door number.
	 * Required: false
	 * Array: false
	 */
	doorRangeMinimum:string = undefined;
	/**
	 * The maximum from or to door number.
	 * Required: false
	 * Array: false
	 */
	doorRangeMaximum:string = undefined;
	/**
	 * The parity of from or to door numbers.
	 * Required: false
	 * Array: false
	 */
	doorRangeOddOrEven:string = undefined;
	/**
	 * Indicates if instructions not involving any door number are included.
	 * Required: false
	 * Array: false
	 */
	includeNonDoorInstructionsInd:boolean = undefined;
	/**
	 * The transaction identifier that created the hostler instruction.
	 * Required: false
	 * Array: false
	 */
	transactionId:string = undefined;
	/**
	 * Pagination information to retrieve the list.
	 * Required: false
	 * Array: false
	 */
	listInfo:ListInfo = undefined;
}
/**
 * Response to the request to list hostler instructions by SIC or hostler ID.
 */
export class ListHostlerInstructionsResp {
	/**
	 * Instructions on how to work the yard.
	 * Required: false
	 * Array: true
	 */
	hostlerInstructions:HostlerInstruction[] = undefined;
	/**
	 * Total count of instructions matching the input parameters exclusive of the pagination.
	 * Required: false
	 * Array: false
	 */
	totalInstructionCount:number = undefined;
	/**
	 * Pagination information to retrieve the list.
	 * Required: false
	 * Array: false
	 */
	listInfo:ListInfo = undefined;
}
/**
 * Request to upsert dependent hostler instructions.
 */
export class UpsertDependentHostlerInstructionsRqst {
	/**
	 * A list of instructions on how to work the yard, which will create new records or update existing ones.
	 * Required: true
	 * Array: true
	 */
	hostlerInstructions:HostlerInstruction[] = undefined;
}
/**
 * Response to the request to upsert dependent hostler instructions.
 */
export class UpsertDependentHostlerInstructionsResp {
	/**
	 * The list of instructions on how to work the yard, which created new records or updated existing ones.
	 * Required: false
	 * Array: true
	 */
	hostlerInstructions:HostlerInstruction[] = undefined;
}
/**
 * Request to upsert a hostler instruction.
 */
export class UpsertHostlerInstructionRqst {
	/**
	 * An instruction on how to work the yard.
	 * Required: true
	 * Array: false
	 */
	hostlerInstruction:HostlerInstruction = undefined;
}
/**
 * Response to the request to upsert a hostler instruction.
 */
export class UpsertHostlerInstructionResp {
	/**
	 * An instruction on how to work the yard.
	 * Required: false
	 * Array: false
	 */
	hostlerInstruction:HostlerInstruction = undefined;
}
/**
 * Path parameter used to delete the parking location that is associated with the ID provided.
 */
export class DeleteParkingLocationPath {
	/**
	 * The unique system generated ID for each parking location.
	 * Required: true
	 * Array: false
	 */
	parkingLocationId:number = undefined;
}
/**
 * Path parameter used to get a parking location by ID.
 */
export class GetParkingLocationByIdPath {
	/**
	 * The system generated unique identifier for the parking location.
	 * Required: true
	 * Array: false
	 */
	parkingLocationId:number = undefined;
}
/**
 * Response to the request to get a parking location by ID.
 */
export class GetParkingLocationByIdResp {
	/**
	 * Details about a parking location within a service center yard.
	 * Required: false
	 * Array: false
	 */
	parkingLocation:ParkingLocation = undefined;
}
/**
 * Path parameter used to list parking locations by SIC.
 */
export class ListParkingLocationsBySicPath {
	/**
	 * The service center for which the parking locations are to be retrieved.
	 * Required: true
	 * Array: false
	 */
	sic:string = undefined;
}
/**
 * Query parameter used to list parking locations by SIC.
 */
export class ListParkingLocationsBySicQuery {
	/**
	 * Pagination information to retrieve the list.
	 * Required: false
	 * Array: false
	 */
	listInfo:ListInfo = undefined;
}
/**
 * Response to the request to list parking locations by SIC.
 */
export class ListParkingLocationsBySicResp {
	/**
	 * Details about a parking location within a SIC yard.
	 * Required: false
	 * Array: true
	 */
	parkingLocation:ParkingLocation[] = undefined;
	/**
	 * Pagination information to retrieve the list.
	 * Required: false
	 * Array: false
	 */
	listInfo:ListInfo = undefined;
}
/**
 * Request to upsert a parking location.
 */
export class UpsertParkingLocationRqst {
	/**
	 * A parking location within a SIC yard.
	 * Required: true
	 * Array: false
	 */
	parkingLocation:ParkingLocation = undefined;
}
/**
 * Response to the request to upsert a parking location.
 */
export class UpsertParkingLocationResp {
	/**
	 * A parking location within a SIC yard.
	 * Required: false
	 * Array: false
	 */
	parkingLocation:ParkingLocation = undefined;
}
/**
 * Path parameter used to delete the template job that is associated with the ID provided.
 */
export class DeleteTemplateJobPath {
	/**
	 * The unique system generated ID for each template job.
	 * Required: true
	 * Array: false
	 */
	templateJobId:number = undefined;
}
/**
 * Path parameter used to get a template job by ID.
 */
export class GetTemplateByIdPath {
	/**
	 * Identifier of the preference grouping.
	 * Required: true
	 * Array: false
	 */
	groupingId:number = undefined;
	/**
	 * Part of identifier of the template.
	 * Required: true
	 * Array: false
	 */
	templateSequenceNbr:number = undefined;
}
/**
 * Response to the request to get a template by ID.
 */
export class GetTemplateByIdResp {
	/**
	 * Details about the template that corresponds with the composite templateId provided.
	 * Required: false
	 * Array: false
	 */
	template:Template = undefined;
}
/**
 * Path parameter used to get a template job by ID.
 */
export class GetTemplateJobByIdPath {
	/**
	 * The unique system generated ID for each template job.
	 * Required: true
	 * Array: false
	 */
	templateJobId:number = undefined;
}
/**
 * Response to the request to get a template job by ID.
 */
export class GetTemplateJobByIdResp {
	/**
	 * Details about the template job that corresponds with the templateJobId provided.
	 * Required: false
	 * Array: false
	 */
	templateJob:TemplateJob = undefined;
}
/**
 * Path parameter used to list template jobs by SIC.
 */
export class ListTemplateJobsBySicPath {
	/**
	 * The service center for which the template jobs are to be retrieved.
	 * Required: true
	 * Array: false
	 */
	sicCode:string = undefined;
}
/**
 * Query parameter used to list template jobs by SIC.
 */
export class ListTemplateJobsBySicQuery {
	/**
	 * The identifier of the shift. Types: I - Inbound, D - Day FAC, O - Outbound, N - Night FAC, X - N/A.
	 * Required: false
	 * Array: false
	 */
	shiftCd:string = undefined;
	/**
	 * Pagination information to retrieve the list.
	 * Required: false
	 * Array: false
	 */
	listInfo:ListInfo = undefined;
}
/**
 * Response to the request to list template jobs by SIC.
 */
export class ListTemplateJobsBySicResp {
	/**
	 * Details about template jobs within a SIC yard.
	 * Required: false
	 * Array: true
	 */
	templateJobs:TemplateJob[] = undefined;
	/**
	 * Pagination information to retrieve the list.
	 * Required: false
	 * Array: false
	 */
	listInfo:ListInfo = undefined;
}
/**
 * Path parameter used to list template by SIC.
 */
export class ListTemplatesBySicPath {
	/**
	 * The service center for which the templates are to be retrieved.
	 * Required: true
	 * Array: false
	 */
	sicCode:string = undefined;
}
/**
 * Query parameter used to list template by SIC.
 */
export class ListTemplatesBySicQuery {
	/**
	 * The identifier of the shift. Types: I - Inbound, D - Day FAC, O - Outbound, N - Night FAC, X - N/A.
	 * Required: false
	 * Array: false
	 */
	shiftCd:string = undefined;
	/**
	 * Pagination information to retrieve the list.
	 * Required: false
	 * Array: false
	 */
	listInfo:ListInfo = undefined;
}
/**
 * Response to the request to list template by SIC.
 */
export class ListTemplatesBySicResp {
	/**
	 * Details about templates for a SIC.
	 * Required: false
	 * Array: true
	 */
	templates:Template[] = undefined;
	/**
	 * Pagination information to retrieve the list.
	 * Required: false
	 * Array: false
	 */
	listInfo:ListInfo = undefined;
}
/**
 * Request to upsert a template job.
 */
export class UpsertTemplateJobRqst {
	/**
	 * The template job that will be used to create a new record or update an existing one.
	 * Required: true
	 * Array: false
	 */
	templateJob:TemplateJob = undefined;
}
/**
 * Response to the request to upsert a template job.
 */
export class UpsertTemplateJobResp {
	/**
	 * The template job that was used to create a new record or update an existing one.
	 * Required: false
	 * Array: false
	 */
	templateJob:TemplateJob = undefined;
}
/**
 * DEF: This represents a Group Request with their related children requests
 */
export class GroupRequest {
	/**
	 * Information of the parent request
	 * Required: false
	 * Array: false
	 */
	parent:HostlerInstruction = undefined;
	/**
	 * Information of each child request
	 * Required: false
	 * Array: true
	 */
	request:HostlerInstruction[] = undefined;
}
/**
 * The result from creating an instruction and any error that was captured.  If the error is populated, then the instruction was not created.
 */
export class InstructionResult {
	/**
	 * Details about the hostler instruction.
	 * Required: false
	 * Array: false
	 */
	instruction:DependentHostlerInstruction = undefined;
	/**
	 * The error for why the instruction failed to be created.
	 * Required: false
	 * Array: false
	 */
	error:DataValidationError = undefined;
}
/**
 * Details of a template with last template job info.
 */
export class Template {
	/**
	 * The identifier of the group preference. Â In combination with the templateSequenceNbr, this is the unique system generated ID for each template.
	 * Required: false
	 * Array: false
	 */
	groupingId:number = undefined;
	/**
	 * The identifier for the template sequence number. Â In combination with the groupingId, this is the unique system generated ID for each template.
	 * Required: false
	 * Array: false
	 */
	templateSequenceNbr:number = undefined;
	/**
	 * The name of the template.
	 * Required: false
	 * Array: false
	 */
	templateName:string = undefined;
	/**
	 * The name of the person who modified the template last.
	 * Required: false
	 * Array: false
	 */
	lastModifiedBy:string = undefined;
	/**
	 * The timestamp when the template was last modified.
	 * Required: false
	 * Array: false
	 */
	lastModifiedDateTime:Date = undefined;
	/**
	 * The name of the person who ran the template last.
	 * Required: false
	 * Array: false
	 */
	lastRunBy:string = undefined;
	/**
	 * The timestamp for when the template was last run.
	 * Required: false
	 * Array: false
	 */
	lastRunDateTime:Date = undefined;
	/**
	 * The identifier for the job records that were last run.
	 * Required: false
	 * Array: false
	 */
	lastRunTemplateJobId:number = undefined;
	/**
	 * The list of instructions associated with this template.
	 * Required: false
	 * Array: true
	 */
	templateInstructions:DependentHostlerInstruction[] = undefined;
	/**
	 * The number of instructions that were created successfully.
	 * Required: false
	 * Array: false
	 */
	successfulCount:number = undefined;
	/**
	 * The number of instructions that failed to be created.
	 * Required: false
	 * Array: false
	 */
	failedCount:number = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * Records the hostler on duty information
 */
export class Hostler {
	/**
	 * Location sic code where the hostler reside
	 * Required: false
	 * Array: false
	 */
	hostlerSicCd:string = undefined;
	/**
	 * Employee ID of the hostler on duty
	 * Required: false
	 * Array: false
	 */
	hostlerEmployeeId:string = undefined;
	/**
	 * Employee details for the hostler.
	 * Required: false
	 * Array: false
	 */
	hostlerEmployee:InterfaceEmployee = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * DEF: Log of all instructions to move equipment
 */
export class HostlerInstruction {
	/**
	 * Instruction ID: Unique system generated ID for each instruction
	 * Required: false
	 * Array: false
	 */
	instructionId:number = undefined;
	/**
	 * Instruction SIC: SIC where the instruction was issued.
	 * Required: false
	 * Array: false
	 */
	instructionSic:string = undefined;
	/**
	 * Instruction Code: Identifies the instruction type
	 * 
	 * Valid Values:
	 * MOVE
	 * SWAP
	 * OTHER
	 * Required: false
	 * Array: false
	 */
	instructionCd:HostlerInstructionCd = undefined;
	/**
	 * Equipment ID: ID of the equipment to be moved
	 * Required: false
	 * Array: false
	 */
	equipmentId:number = undefined;
	/**
	 * Equipment Prefix: Prefix for the equipment to be moved.
	 * Required: false
	 * Array: false
	 */
	equipmentPrefix:string = undefined;
	/**
	 * Equipment Suffix: Suffix for the equipment to be moved.
	 * Required: false
	 * Array: false
	 */
	equipmentSuffix:string = undefined;
	/**
	 * Equipment Type Code: Type of the equipment to be moved.
	 * 
	 * Valid Values:
	 * T - L/H Trailer
	 * V - Van
	 * Z - Straight Truck
	 * P - Tractor
	 * D - Dolly
	 * O - Other
	 * W- Rental
	 * Required: false
	 * Array: false
	 */
	equipmentTypeCd:EquipmentTypeCd = undefined;
	/**
	 * Carrier SCAC Code: SCAC Code identifying the 3rd Party Carrier owner of the equipment.
	 * Required: false
	 * Array: false
	 */
	carrierScac:string = undefined;
	/**
	 * Trailer Length: Length of the equipment to be moved.
	 * 
	 * Valid Values:
	 * 28
	 * 32
	 * 33
	 * 42
	 * 45
	 * 48
	 * 53
	 * Required: false
	 * Array: false
	 */
	trailerLengthNbr:string = undefined;
	/**
	 * From Location ID: ID of the current location of the equipment from where it will be moved, mutually exclusive with the FROM_LOC_DOOR.
	 * Required: false
	 * Array: false
	 */
	fromLocationId:number = undefined;
	/**
	 * To Location ID: ID of the new location to which the equipment is to be moved, mutually exclusive with TO_LOC_DOOR
	 * Required: false
	 * Array: false
	 */
	toLocationId:number = undefined;
	/**
	 * From Location Door: Current door location from where the equipment will be moved, mutually exclusive with FROM_LOC_ID
	 * Required: false
	 * Array: false
	 */
	fromLocationDoor:string = undefined;
	/**
	 * To Location Door: New door location to which the equipment is to be moved
	 * mutually exclusive with TO_LOC_ID
	 * Required: false
	 * Array: false
	 */
	toLocationDoor:string = undefined;
	/**
	 * Instruction Status Code: Current status of the instruction
	 * 
	 * Valid Values:
	 * P - PENDING
	 * I - IN PROGRESS
	 * C - COMPLETED
	 * X - CANCELLED
	 * Required: false
	 * Array: false
	 */
	instructionStatusCd:InstructionStatCd = undefined;
	/**
	 * Assigned To: Employee ID of Hostler who the instruction was assigned to
	 * Required: false
	 * Array: false
	 */
	assignedTo:string = undefined;
	/**
	 * Assigned By: Employee ID of FOS who assigned the instruciton
	 * Required: false
	 * Array: false
	 */
	assignedBy:string = undefined;
	/**
	 * Assigned Timestamp: Date/Time of when the instruction was assigned.
	 * Required: false
	 * Array: false
	 */
	assignedDateTimeUtc:Date = undefined;
	/**
	 * Executed by: Employee ID of person who executed the instruction.
	 * Required: false
	 * Array: false
	 */
	executedBy:string = undefined;
	/**
	 * Start Timestamp: Date/Time of when the execution started.
	 * Required: false
	 * Array: false
	 */
	startDateTimeUtc:Date = undefined;
	/**
	 * End Timestamp: Date/Time of when the execution ended.
	 * Required: false
	 * Array: false
	 */
	endDateTimeUtc:Date = undefined;
	/**
	 * Priority Number: Priority of the instruction.
	 * Required: false
	 * Array: false
	 */
	priorityNbr:string = undefined;
	/**
	 * Supervisor Attention Required Indicator: Indicates whether the instruction requires supervisor attention.
	 * Required: false
	 * Array: false
	 */
	supervisorAttnReqdInd:boolean = undefined;
	/**
	 * Start Latitude: Start latitude of the hostler's location when they started the instruction.
	 * Required: false
	 * Array: false
	 */
	startLatitudeNbr:number = undefined;
	/**
	 * Start Longitude: Start longitude of the hostler's location when they started the instruction.
	 * Required: false
	 * Array: false
	 */
	startLongitudeNbr:number = undefined;
	/**
	 * Hook Latitude: Hook latitude of the hostler's location when they actually got to the trailer and hooked it, moving the equipment. (Different from the start because presumably they had to drive somewhere to get to the trailer).
	 * Required: false
	 * Array: false
	 */
	hookLatitudeNbr:number = undefined;
	/**
	 * Hook Longitude: Hook longitude of the hostler's location when they actually got to the trailer and hooked it, moving the equipment. (Different from the start because presumably they had to drive somewhere to get to the trailer).
	 * Required: false
	 * Array: false
	 */
	hookLongitudeNbr:number = undefined;
	/**
	 * End Latitude: End latitude of the hostler's location when they ended the instruction.
	 * Required: false
	 * Array: false
	 */
	endLatitudeNbr:number = undefined;
	/**
	 * End Longitude: End longitude of the hostler's location when they ended the instruction.
	 * Required: false
	 * Array: false
	 */
	endLongitudeNbr:number = undefined;
	/**
	 * Special Instruction: Any special instruction for the move.
	 * Required: false
	 * Array: false
	 */
	specialInstruction:string = undefined;
	/**
	 * Hostler Comments: Comments by the holster about the move.
	 * Required: false
	 * Array: false
	 */
	hostlerComments:string = undefined;
	/**
	 * Sequence Number: Sequence of how the instruction will be executed in a group of instructions.
	 * Required: false
	 * Array: false
	 */
	sequenceNbr:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	correlationId:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	recordVersionNbr:number = undefined;
	/**
	 * Additional 
	 * 
	 * property that further describes the equipment.  Added 06/19/2019
	 * 
	 * VALID VALUES
	 * EMPTY
	 * LOADED
	 * HEADLOAD
	 * Required: false
	 * Array: false
	 */
	equipmentQualifierCd:EquipmentQualifierCd = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
	/**
	 * Employee details for the hostler who executed the instruction.
	 * Required: false
	 * Array: false
	 */
	executedByEmployee:InterfaceEmployee = undefined;
	/**
	 * Employee details of the person creating the instruction.
	 * Required: false
	 * Array: false
	 */
	createdByEmployee:InterfaceEmployee = undefined;
	/**
	 * Employee details of FOS who assigned the instruction.
	 * Required: false
	 * Array: false
	 */
	assignedByEmployee:InterfaceEmployee = undefined;
	/**
	 * Employee details of the person to last update the instruction.
	 * Required: false
	 * Array: false
	 */
	updateByEmployee:InterfaceEmployee = undefined;
	/**
	 * Employee details for the hostler assigned to the instruction.
	 * Required: false
	 * Array: false
	 */
	assignedToEmployee:InterfaceEmployee = undefined;
	/**
	 * For the executable instruction within a SWAP, this will contain the parent instruction ID.
	 * Required: false
	 * Array: false
	 */
	parentInstanceId:number = undefined;
}
/**
 * 
 */
export class HostlerInstructionHist {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	instructionId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	histCreatedDateTime:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	histActionCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	instructionSic:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	instructionCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	equipmentId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	equipmentPrefix:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	equipmentSuffix:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	equipmentTypeCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	carrierScac:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	trailerLengthNbr:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	fromLocationId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	toLocationId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	fromLocationDoor:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	toLocationDoor:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	instructionStatusCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	assignedTo:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	assignedBy:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	assignedDateTimeUtc:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	executedBy:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	startDateTimeUtc:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	endDateTimeUtc:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	priorityNbr:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	supervisorAttnReqdInd:boolean = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	startLatitudeNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	startLongitudeNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	hookLatitudeNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	hookLongitudeNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	endLatitudeNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	endLongitudeNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	specialInstruction:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	hostlerComments:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	parentInstId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	sequenceNbr:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	correlationId:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	recordVersionNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	equipmentQualifierCd:EquipmentQualifierCd = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	srcCreatedBy:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	srcCreatedDateTime:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	srcCreatedProgramId:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	srcLastUpdateBy:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	srcLastUpdateDateTime:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	srcLastUpdateProgramId:string = undefined;
}
/**
 * DEF: This represents each equipment that is parked in one of the parking locations. 
 */
export class ParkedEquipment {
	/**
	 * Parking Location ID: Unique system generated ID to identify each Parking Location
	 * Required: false
	 * Array: false
	 */
	parkingLocationId:number = undefined;
	/**
	 * Equipment ID: Uniquely identifies the parked equipment
	 * Required: false
	 * Array: false
	 */
	equipmentId:number = undefined;
	/**
	 * Spot ID: Identifies the parking spot where the equipment is parked
	 * Required: false
	 * Array: false
	 */
	spotId:string = undefined;
	/**
	 * SIC: SIC location where the equipment is parked
	 * Required: false
	 * Array: false
	 */
	sic:string = undefined;
	/**
	 * Parked Latitude: Latitude of the location where the equipment is parked.
	 * Required: false
	 * Array: false
	 */
	parkedLatitudeNbr:number = undefined;
	/**
	 * Parked Longitude: Longitude of the location where the equipment is parked.
	 * Required: false
	 * Array: false
	 */
	parkedLongitudeNbr:number = undefined;
	/**
	 * Parked Timestamp: Date/Time when an equipment was parked.
	 * Required: false
	 * Array: false
	 */
	parkedDateTimeUtc:Date = undefined;
	/**
	 * Parked By: Employee ID of person who parked the equipment
	 * Required: false
	 * Array: false
	 */
	parkedBy:string = undefined;
	/**
	 * Indicates whether to add, update or delete this entry. This will be used for merging lists.
	 * 
	 * A - Add entry to list
	 * D - Delete entry from list
	 * U - Update entry
	 * Required: false
	 * Array: false
	 */
	listActionCd:ActionCd = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * 
 */
export class ParkedEquipmentHist {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	parkingLocationId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	equipmentId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	spotId:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	sic:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	histCreatedDateTime:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	histActionCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	parkedLatitudeNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	parkedLongitudeNbr:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	parkedDateTimeUtc:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	parkedBy:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	srcCreatedBy:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	srcCreatedDateTime:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	srcCreatedProgramId:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	srcLastUpdateBy:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	srcLastUpdateDateTime:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	srcLastUpdateProgramId:string = undefined;
}
/**
 * DEF: This represents the different areas designated as parking locations in a service center. A service center may have one or more such locations. The locations will be identified by a name and a description like NE Corner. But a polygon will also define each of these areas.
 */
export class ParkingLocation {
	/**
	 * Parking Location ID: Unique system generated ID to identify each Parking Location
	 * Required: false
	 * Array: false
	 */
	parkingLocationId:number = undefined;
	/**
	 * Parking Location Name: A name given to this location.
	 * Required: false
	 * Array: false
	 */
	parkingLocationName:string = undefined;
	/**
	 * Parking Location Description: A description for identifying and navigating to the location like North East Corner of the Service Center
	 * Required: false
	 * Array: false
	 */
	parkingLocationDescription:string = undefined;
	/**
	 * Parking Location Type: Type of Parking location.
	 * Valid Values:
	 * TRAP, APPT, EMPTY, and TRACTORS
	 * Required: false
	 * Array: false
	 */
	parkingLocationTypeCd:ParkingLocationTypeCd = undefined;
	/**
	 * Parking Location Capacity: Total number of equipment that can be parked in this location
	 * Required: false
	 * Array: false
	 */
	parkingLocationCapacity:string = undefined;
	/**
	 * SIC: SIC where the parking location is defined
	 * Required: false
	 * Array: false
	 */
	sic:string = undefined;
	/**
	 * Current Count: Total number of equipment currently parked in this location
	 * Required: false
	 * Array: false
	 */
	currentCount:string = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * 
 */
export class ParkingLocationHist {
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	parkingLocationId:number = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	histCreatedDateTime:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	histActionCd:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	parkingLocationName:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	parkingLocationDescription:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	parkingLocationTypeCd:ParkingLocationTypeCd = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	parkingLocationCapacity:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	sic:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	currentCount:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	srcCreatedBy:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	srcCreatedDateTime:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	srcCreatedProgramId:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	srcLastUpdateBy:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	srcLastUpdateDateTime:Date = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	srcLastUpdateProgramId:string = undefined;
}
/**
 * DEF: Defines the physical boundary of the parking location as a polygon
 */
export class ParkingLotBoundary {
	/**
	 * Parking Location ID: Unique system generated ID to identify each Parking Location
	 * Required: false
	 * Array: false
	 */
	parkingLocationId:number = undefined;
	/**
	 * SIC: SIC where the parking location is defined.
	 * Required: false
	 * Array: false
	 */
	sic:string = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * DEF: Identifies all the defined parking spots in a parking location
 */
export class ParkingSpot {
	/**
	 * Parking Location ID: Unique system generated ID to identify each Parking Location
	 * Required: false
	 * Array: false
	 */
	parkingLocationId:number = undefined;
	/**
	 * Spot ID: ID that identifies the parking spot.
	 * Required: false
	 * Array: false
	 */
	spotId:number = undefined;
	/**
	 * Spot Latitude: Latitude of the parking spot.
	 * Required: false
	 * Array: false
	 */
	spotLatitudeNbr:number = undefined;
	/**
	 * Spot Longitude: Longitude of the parking spot.
	 * Required: false
	 * Array: false
	 */
	spotLongitudeNbr:number = undefined;
	/**
	 * SIC: SIC where the parking location is defined.
	 * Required: false
	 * Array: false
	 */
	sic:string = undefined;
	/**
	 * Occupied Indicator: Indicates if an equipment is currently parked at the spot.
	 * Required: false
	 * Array: false
	 */
	occupiedInd:boolean = undefined;
	/**
	 * Indicates whether to add, update or delete this entry. This will be used for merging lists.
	 * 
	 * A - Add entry to list
	 * D - Delete entry from list
	 * U - Update entry
	 * Required: false
	 * Array: false
	 */
	listActionCd:ActionCd = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * Record of the job that was run.  Added 01/29/2020
 */
export class TemplateJob {
	/**
	 * Unique identifier for template job.
	 * Required: false
	 * Array: false
	 */
	templateJobId:number = undefined;
	/**
	 * Service Center Code
	 * Required: false
	 * Array: false
	 */
	sicCode:string = undefined;
	/**
	 * Identifier of the shift. 
	 * 
	 * Types: 
	 * I - Inbound
	 * D - Day FAC
	 * O - Outbound
	 * N - Night FAC
	 * X - N/A
	 * Required: false
	 * Array: false
	 */
	shiftCd:string = undefined;
	/**
	 * Template JSON data for the job.
	 * Required: false
	 * Array: false
	 */
	templateJobJson:string = undefined;
	/**
	 * 
	 * Required: false
	 * Array: false
	 */
	correlationId:string = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
	/**
	 * Identifier of the group preference
	 * Required: false
	 * Array: false
	 */
	groupingId:number = undefined;
	/**
	 * Identifier for the tool preference sequence number
	 * Required: false
	 * Array: false
	 */
	toolPrefSequenceNbr:number = undefined;
	/**
	 * Text identifier for template job
	 * Required: false
	 * Array: false
	 */
	templateName:string = undefined;
	/**
	 * Success count
	 * Required: false
	 * Array: false
	 */
	successfulCount:number = undefined;
	/**
	 * Failed count
	 * Required: false
	 * Array: false
	 */
	failedCount:number = undefined;
	/**
	 * Time job ran
	 * Required: false
	 * Array: false
	 */
	jobRunDateTimeUtc:Date = undefined;
	/**
	 * User or service that ran the job.
	 * Required: false
	 * Array: false
	 */
	runBy:string = undefined;
}
/**
 * Details about employees that process and approve claims.
 */
export class InterfaceEmployee {
	/**
	 * Employee Id of the Claims employee.
	 * Required: false
	 * Array: false
	 */
	employeeId:string = undefined;
	/**
	 * First Name of the Claims employee.
	 * Required: false
	 * Array: false
	 */
	firstName:string = undefined;
	/**
	 * Middle Name of the Supervisor of the Claims employee.
	 * Required: false
	 * Array: false
	 */
	middleName:string = undefined;
	/**
	 * Last Name of the Supervisor of the Claims employee.
	 * Required: false
	 * Array: false
	 */
	lastName:string = undefined;
	/**
	 * Job Description of the Supervisor of the Claims employee.
	 * Required: false
	 * Array: false
	 */
	jobDescription:string = undefined;
	/**
	 * The domicile SIC location for the employee.
	 * Required: false
	 * Array: false
	 */
	domicileSicCd:string = undefined;
	/**
	 * The employee id of this employee's supervisor.
	 * Required: false
	 * Array: false
	 */
	supervisorEmployeeId:string = undefined;
	/**
	 * Desc: The business phone number of the employee.  It is sourced from HRS_EMPL_PHONE_VW in DB2 for typ_cd = 'B'.  01/10/2019 Added by Cathy
	 * Required: false
	 * Array: false
	 */
	businessPhoneTxt:string = undefined;
	/**
	 * Audit information such as create and/or update transaction details - who, when and which program/transaction
	 * Required: false
	 * Array: false
	 */
	auditInfo:AuditInfo = undefined;
}
/**
 * The result from creating an instruction and any error that was captured.  If the error is populated, then the instruction was not created.
 */
export class DependentHostlerInstruction extends HostlerInstruction {
	/**
	 * Details about the hostler instruction.
	 * Required: false
	 * Array: false
	 */
	dependentInstruction:HostlerInstruction = undefined;
}



